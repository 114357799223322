<script setup>
import {useRoomStore} from "../stores/rooms"

const roomStore = useRoomStore()

const props = defineProps({
    type: Object
})

</script>
<template>
    <button @click="roomStore.setActiveRoomType(type.key)" class="btn-outline-inactive w-100 filter-button align-items-center py-1 px-2 justify-content-start"
            :class="{'btn-outline':props.type.key === roomStore.activeRoomType}"
    >
        <span class="d-flex me-2 ms-1 my-1 align-items-center">
            <component :is="`v-icon-${type.key}`"></component>
        </span>
        {{ type.value }}
    </button>
</template>
