<script setup>
import {ref, defineEmits} from "vue";
import {setErrors} from '@formkit/vue'
import {default as vPasswordField} from './PasswordField'

let formLoading = ref(false);

const emit = defineEmits(['switchForm', 'authSuccess'])

const props = defineProps({
    flow: {type: String, required: false, default: 'page'}
})

async function handleSubmit(credentials) {

    formLoading.value = true;

    return axios.post(route('login'), credentials)
        .then(() => {
            if (props.flow === 'page') {
                window.location.href = route('home');
            } else {
                emit('authSuccess')
            }
        })
        .catch((error) => {
            formLoading.value = false;
            setErrors(
                'login-form',
                [error.response.data.message],
                error.response.data.errors
            )
        });
}

function switchForm() {
    emit('switchForm', 'register')
}
</script>

<template>
    <FormKit type="form" @submit="handleSubmit"
             id="login-form"
             :actions="false"
             :classes="{
                    form: {
                      'd-flex flex-column': true
                    }
                  }"
    >
        <input type="hidden" name="remember" value="1">

        <div class="form-item">
            <FormKit id="email" type="email" name="email"
                     :label="$t('user.email')"
                     validation="required|email"
            />
        </div>

        <div class="form-item mb-2">
            <v-password-field></v-password-field>
        </div>

        <div class="text-right mb-4">
            <a :href="route('password.request')">Wachtwoord vergeten?</a>
        </div>

        <div class="d-flex align-items-center justify-content-between form-buttons">
            <a :href="route('register')" v-if="flow === 'page'">{{ $t('user.create_account') }}</a>
            <button type="button" @click="switchForm()" v-if="props.flow === 'inline'">
                {{ $t('user.create_account') }}
            </button>

            <FormKit
                type="submit"
                class="btn btn-primary"
                :label="$t('user.login')"
                :classes="{
                    input: {
                      'btn btn-primary btn-loader':true
                    }
                  }"
                :disabled="formLoading"
            />
        </div>
    </FormKit>
</template>
