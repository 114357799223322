<script setup>
import {useReservationStore} from "../stores/reservation"

const reservationStore = useReservationStore()
import _ from 'lodash'

let price = reservationStore.getPrice

</script>
<template>

    <template v-if="!_.isNull(reservationStore.discount)">

        <div class="col-12 d-flex justify-content-end align-items-center">
            <h4 class="mb-0 me-2">{{ $t('reservation.price.subtotal') }}</h4>
            <h4 class="mb-0 text-right price">{{ reservationStore.discount.room_price.formatted }}</h4>
        </div>
        <div class="col-12 d-flex justify-content-end align-items-center">
            <h4 class="mb-0 me-2">{{ $t('reservation.price.discount') }}</h4>
            <h4 class="mb-0 text-right price">- {{ reservationStore.discount.discount.formatted }}</h4>
        </div>

        <div class="col-12 d-flex justify-content-end align-items-center">
            <h4 class="mb-0 me-2">{{ $t('reservation.price.total') }}</h4>
            <h3 class="mb-0 text-right price">{{ reservationStore.discount.discount_price.formatted }}</h3>
        </div>

    </template>

    <template v-if="_.isNull(reservationStore.discount)">

        <div class="col-12 d-flex justify-content-end align-items-center">
            <h4 class="mb-0 me-2">{{ $t('reservation.price.total') }}</h4>
            <h3 class="mb-0 text-right price">{{ reservationStore.time.price.formatted }}</h3>
        </div>

    </template>

</template>
