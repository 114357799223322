<script setup>
import {ref} from 'vue'

const catRef = ref(null)
const catItems = ref(null)

let openCategory = ref();
let openItem = ref();

let catHeight = ref(0)
let itemHeight = ref(0)

let items = require ('../../lang/faqItems')

function toggleCategory(index) {
    openItem.value = null
    catItems.value = catRef.value[index].getElementsByClassName('items')[0]
    catHeight.value = catItems.value.clientHeight + 'px';

    if (index === openCategory.value) {
        setTimeout(function () {
            openCategory.value = null
        }, 1);
        return
    }

    setTimeout(function () {
        catHeight.value = 'auto';
    }, 300);

    openCategory.value = index
}

function toggleItem(index) {
    itemHeight.value = catItems.value.getElementsByClassName('answer')[index].clientHeight


    if (index === openItem.value) {
        openItem.value = null
        return
    }

    openItem.value = index
}

</script>

<template>

    <div class="faq-category" v-for="(category,index) in items" ref="catRef" :class="{open: openCategory === index}">
        <button @click="toggleCategory(index)">{{ category.title }}</button>

        <div class="items-wrapper"
             :style="[openCategory === index ? {'height': catHeight} : {'height': 0}]"
        >
            <div class="items">
                <div class="item" v-for="(item,itemIndex) in category.items" :ref="'itemRef'+index" :class="{open: openItem === itemIndex}">
                    <button @click="toggleItem(itemIndex)">{{ item.title }}</button>
                    <div class="answer-wrapper" :style="[openItem === itemIndex ? {'height': itemHeight+'px'} : {}]">
                        <div class="answer" v-html="item.description"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>
