<script setup>
import {ref} from "vue";
import {setErrors} from '@formkit/vue'
import {useToast} from "vue-toastification";

const toast = useToast();

import {useI18n} from 'vue-i18n'

const i18n = useI18n();

const props = defineProps({
    user: Object
});

let formLoading = ref(false);

async function handleSubmit(credentials) {

    formLoading.value = true;

    credentials._method = 'put';

    await axios.post(route('account.update'), credentials)
        .then((response) => {
            formLoading.value = false;
            toast.success(i18n.t('messages.account.success'));
        })
        .catch((error) => {

            formLoading.value = false;

            if (error.response.data) {
                setErrors(
                    'register-form',
                    [],
                    error.response.data.errors
                )

                toast.warning(error.response.data.message);
            }
        });
}
</script>

<template>
    <FormKit type="form" @submit="handleSubmit"
             id="register-form"
             :actions="false"
             :classes="{
                    form: {
                      'd-flex flex-column': true
                    }
                  }"
    >

        <div class="row">
            <div class="col-6 pe-2">
                <div class="form-item">
                    <FormKit id="first_name" class="" type="text" name="first_name"
                             :label="$t('user.first_name')"
                             validation="required"
                             :value="user.first_name"
                    />
                </div>
            </div>

            <div class="col-6 ps-2">
                <div class="form-item">
                    <FormKit id="last_name" class="" type="text" name="last_name"
                             :label="$t('user.last_name')"
                             validation="required"
                             :value="user.last_name"
                    />
                </div>
            </div>
        </div>

        <div class="form-item">
            <FormKit id="email" type="email" name="email"
                     :label="$t('user.email')"
                     validation="required|email"
                     :value="user.email"
            />
        </div>

        <div class="form-item">
            <FormKit id="phone_number" class="" type="text" name="phone_number"
                     :label="$t('user.phone_number')"
                     validation="required"
                     :value="user.phone_number"
            />
        </div>

        <template v-if="user.address">
            <div class="form-item">
                <FormKit id="company_name" class="" type="text" name="company_name"
                         :label="$t('user.company_name')"
                         validation="required"
                         :value="user.address.company_name"
                />
            </div>

            <div class="form-item">
                <FormKit id="address" class="" type="text" name="address"
                         :label="$t('user.address')"
                         validation="required"
                         :value="user.address.address"
                />
            </div>

            <div class="row">
                <div class="col-4 pe-2">
                    <div class="form-item">
                        <FormKit id="postal_code" class="" type="text" name="postal_code"
                                 :label="$t('user.postal_code')"
                                 validation="required"
                                 :value="user.address.postal_code"
                        />
                    </div>
                </div>

                <div class="col-8 ps-2">
                    <div class="form-item">
                        <FormKit id="city" class="" type="text" name="city"
                                 :label="$t('user.city')"
                                 validation="required"
                                 :value="user.address.city"
                        />
                    </div>
                </div>
            </div>

        </template>

        <div class="form-buttons">
            <FormKit
                type="submit"
                class="btn btn-primary w-100"
                :label="$t('user.save')"
                :classes="{
                    input: {
                      'btn btn-primary w-100 btn-loader':true
                    }
                  }"
                :disabled="formLoading"
            />

        </div>
    </FormKit>
</template>
