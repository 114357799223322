<script setup>
import {useToast} from "vue-toastification";
import _ from "lodash";
import {nextTick} from "vue";

const toast = useToast();

const props = defineProps({
    message: String,
    type: {
        type: String,
        required: false,
        default: "success"
    }
});

if (!_.isNull(props.message)) {
    nextTick(() => {
        toast(
            props.message,
            {
                type: props.type
            }
        )
    });
}
</script>
<template>

</template>
