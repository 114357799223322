<script setup>
import {ref, computed} from "vue";

import Datepicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'

import {useReservationStore} from "../stores/reservation"
const reservationStore = useReservationStore()
let date = ref(reservationStore.date)

const allowedDates = computed(() => {
    const date = new Date()
    const dates = []

    for (let i = 1; i <= 14; i++) {
        dates.push(new Date(date))
        date.setDate(date.getDate() + 1)
    }

    return dates;
});
</script>
<template>
    <Datepicker
        v-model="date"
        :inline="true"
        :dark="true"
        :enableTimePicker="false"
        :allowedDates="allowedDates"
        locale="nl"
        monthNameFormat="long"
        :autoApply="true"
        @update:modelValue="reservationStore.setDate"
        :monthChangeOnScroll="false"
    ></Datepicker>
</template>
<style lang="scss" scoped>
:deep(.dp__theme_dark) {
    --dp-background-color: #2A312D;
    --dp-text-color: #ffffff;
    --dp-hover-color: #484848;
    --dp-hover-text-color: #ffffff;
    --dp-hover-icon-color: #959595;
    --dp-primary-color: #2D9E59;
    --dp-primary-text-color: #ffffff;
    --dp-secondary-color: #a9a9a9;
    --dp-border-color: #2d2d2d;
    --dp-menu-border-color: #2d2d2d;
    --dp-border-color-hover: #aaaeb7;
    --dp-disabled-color: #737373;
    --dp-scroll-bar-background: #212121;
    --dp-scroll-bar-color: #484848;
    --dp-success-color: #00701a;
    --dp-success-color-disabled: #428f59;
    --dp-icon-color: #959595;
    --dp-danger-color: #e53935;

    border: 0px;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;

    .dp__cell_inner {
        border-radius: 50%;
        margin: 1px;

        &:not(.dp__cell_disabled){
            font-weight: bold;
        }

        &.dp__cell_offset:not(.dp__cell_disabled){
            color: var(--dp-text-color)
        }
    }

    .dp__month_year_select {
        width: 100%;
        font-weight: bold;
        text-transform: capitalize;

        & + .dp__month_year_select {
            display: none;
        }
    }
}
</style>
