<script setup>
import {useReservationStore} from "../stores/reservation"
import {DateTime} from 'luxon'
import Popper from "vue3-popper";
import {default as vDiscount} from './Discount'
import {default as vReservationPrice} from './ReservationPrice'

const reservationStore = useReservationStore()
</script>

<template>

    <h2>{{$t('reservation.check_pay')}}</h2>

    <div class="border reservation-summary">

        <div class="image-shape">
            <img :src="reservationStore.room.image_path" :alt="reservationStore.room.name" v-if="reservationStore.room.image_path">
            <v-image-placeholder v-if="!reservationStore.room.image_path"></v-image-placeholder>
        </div>

        <div class="row">
            <div class="col-6 pe-0">
                <h3 class="mb-2">{{reservationStore.room.name}}</h3>
                <div class="icon-text mb-2">
                    <v-icon-location></v-icon-location>
                    {{reservationStore.room.location.name}}
                </div>
                <div class="icon-text mb-2">
                    <component :is="`v-icon-${reservationStore.room.type}`"></component>
                    {{reservationStore.room.type_label}}
                </div>
            </div>

            <div class="col-6">
                <h3 class="mb-2">{{$t('reservation.date_time')}}</h3>
                <div class="icon-text mb-2">
                    <v-icon-calendar></v-icon-calendar>
                    {{ DateTime.fromISO(reservationStore.date).toFormat('dd-MM-yyyy') }}
                </div>
                <div class="icon-text mb-2">
                    <v-icon-clock></v-icon-clock>
                    {{ DateTime.fromISO(reservationStore.time.startTime).toFormat('HH:mm') }}
                </div>
            </div>
            <div class="col-12">
                <v-discount></v-discount>
            </div>
            <div class="col-12">
                <hr class="my-3">
            </div>

           <v-reservation-price></v-reservation-price>

        </div>
    </div>

    <div class="d-flex justify-content-between align-items-start mt-3">

        <div class="form-item form-type-checkbox me-3">
            <FormKit id="need_support" type="checkbox" name="need_support"
                     :label="$t('reservation.need_support')"
                     v-model="reservationStore.needsSupport"
            />
        </div>
    </div>

    <div class="form-item form-type-checkbox">
        <FormKit id="terms_of_service" type="checkbox" name="terms_of_service" label=""
                 v-model="reservationStore.terms_agreed"
                 validation="required"
        />
        <label for="terms_of_service">{{ $t('user.agree') }} <a :href="route('terms')" target="_blank" >{{ $t('user.terms') }}</a> </label>
    </div>


</template>
