<script setup>
import {default as vTimeslot} from './Timeslot'
import {default as vLoader} from '../../../components/Loader'
import {useReservationStore} from "../stores/reservation"
import _ from 'lodash'

const reservationStore = useReservationStore()

</script>
<template>
    <v-loader v-if="_.isNull(reservationStore.timeslots)"></v-loader>
    <v-timeslot v-for="timeslot in reservationStore.timeslots" :timeslot="timeslot"></v-timeslot>
</template>
