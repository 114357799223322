<script setup>
import {ref} from 'vue'
import {useReservationStore} from "../stores/reservation"
import {useToast} from "vue-toastification";
import {useI18n} from 'vue-i18n'

const toast = useToast();
const reservationStore = useReservationStore()
const i18n = useI18n();

let form = ref('register')

function switchForm(value) {
    form.value = value;
}

function authSuccess() {
    if (form.value === 'login') {
        toast.success(i18n.t('messages.login.success'));
    } else {
        toast.success(i18n.t('messages.register.success'));
    }
    reservationStore.checkAuth()
    reservationStore.setReservationStep('summary')
}

</script>
<template>
    <div class="container">

        <button class="go-back mb-3" @click="reservationStore.setReservationStep('summary')">
            {{ $t('reservation.back') }}
        </button>

        <div v-if="form === 'register'">
            <div class="card bordered mb-3">
                <h4>{{ $t('reservation.auth.register') }}</h4>
                {{ $t('reservation.auth.register_description') }}
                <button @click="switchForm('login')">{{ $t('reservation.auth.button_login') }}</button>
            </div>
            <v-register-form flow="inline" @switchForm="switchForm" @authSuccess="authSuccess"></v-register-form>
        </div>
        <div v-if="form === 'login'">
            <div class="card bordered mb-3">
                <h4>{{ $t('reservation.auth.login') }}</h4>
                {{ $t('reservation.auth.login_description') }}
                <button @click="switchForm('register')">{{ $t('reservation.auth.button_register') }}</button>
            </div>
            <v-login-form flow="inline" @switchForm="switchForm" @authSuccess="authSuccess"></v-login-form>
        </div>
    </div>
</template>
