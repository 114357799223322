<script setup>
import {ref} from 'vue'
import _ from 'lodash'
import {useReservationStore} from "../../stores/reservation"

const reservationStore = useReservationStore()
reservationStore.setCSRF(document.querySelector('meta[name="csrf-token"]').getAttribute('content'))

let submitDisabled = ref(false);
function disableSubmit(){
    submitDisabled.value = true;
}

</script>
<template>
    <div class="action-bar">
        <div class="container py-2">
            <template v-if="reservationStore.reservationStep === 'date-time'">
                <small><strong>{{ reservationStore.room.name }}</strong></small><br>
                <small class="icon-text small">

                    <v-icon-location></v-icon-location> &nbsp;
                    {{ reservationStore.room.location.name }}
                    &nbsp; &nbsp; &nbsp;
                    <component :is="`v-icon-${reservationStore.room.type}`"></component> &nbsp;
                    {{ reservationStore.room.type_label }}
                </small>

                <div class="d-flex justify-content-between mt-2">
                    <a :href="route('reservations.show',reservationStore.reservation.id)" class="btn-outline-inactive">{{$t('reservation.back')}}</a>
                    <button @click="reservationStore.setReservationStep('summary')" :disabled="_.isNull(reservationStore.time)" class="btn-primary ms-3 w-100">
                        {{$t('reservation.edit.check')}}
                    </button>
                </div>

            </template>

            <template v-if="reservationStore.reservationStep === 'summary'">
                <div class="d-flex justify-content-between mt-2">
                    <button @click="reservationStore.setReservationStep('date-time')" class="btn-outline-inactive">{{$t('reservation.back')}}</button>

                    <form :action="route('reservations.update',reservationStore.reservation.id)" method="post" class="w-100 ms-3" @submit="disableSubmit">
                        <input name="_method" type="hidden" value="PUT">
                        <input type="hidden" name="_token" :value="reservationStore.csrf">
                        <input type="hidden" name="start_time" :value="reservationStore.time.startTime">

                        <button type="submit" class="btn-primary w-100 btn-loader px-0" :disabled="submitDisabled" >
                            {{$t('reservation.edit.submit')}}
                        </button>
                    </form>

                </div>
            </template>

        </div>
    </div>
</template>
