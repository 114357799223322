<script setup>
import {default as vFilters} from './components/Filters'
import {default as vRoomsOverview} from './components/RoomsOverview'
import {useRoomStore} from "./stores/rooms"
const roomStore = useRoomStore()

const props = defineProps({
    roomTypes: Object
})

roomStore.setRoomTypes(props.roomTypes)
roomStore.loadRooms()

</script>
<template>

    <div class="d-flex flex-row justify-content-around my-5">
        <v-filters></v-filters>
    </div>

    <v-rooms-overview></v-rooms-overview>

</template>
