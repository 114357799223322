<script setup>
import {ref} from 'vue'
import Popper from "vue3-popper";
import {default as vDiscountInput} from "./DiscountInput";

let showDiscount = ref(false)

</script>
<template>
    <div class="d-flex align-items-center mt-4">
        <button class="toggle me-4" @click="showDiscount = !showDiscount" :class="{open:showDiscount}">{{$t('reservation.discount.have_code')}}</button>

        <Popper arrow z-index="90" placement="top" :content="$t('reservation.discount.tooltip')">
            <button type="button" class="icon-button">
                <v-icon-info></v-icon-info>
            </button>
        </Popper>

    </div>
    <div v-show="showDiscount">
        <v-discount-input></v-discount-input>
    </div>
</template>
