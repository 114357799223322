<script setup>
import {useRoomStore} from "../stores/rooms"
import {default as vFilter} from './Filter'

const roomStore = useRoomStore()
</script>
<template>

    <template v-for="(type,key) in roomStore.roomTypes">
        <v-filter :type="{key: key, value:type}"></v-filter>
    </template>

</template>
