<script setup>
import {nextTick} from 'vue'
import {default as vDatepicker} from './components/Datepicker'
import {default as vTimeslots} from './components/Timeslots'
import {default as vActions} from './components/Actions'
import {default as vSummary} from './components/Summary'
import {default as vInlineAuthentication} from './components/InlineAuthentication'
import {useReservationStore} from "./stores/reservation"

const reservationStore = useReservationStore()
import _ from 'lodash'
import {useToast} from "vue-toastification";

const toast = useToast();

const props = defineProps({
    message: {
        type: String,
        required: false,
        default: null
    }
});

if (!_.isNull(props.message)) {
    nextTick(() => {
        toast.warning(props.message)
    });
}

</script>
<template>
    <div class="reservation-overlay pt-5" :class="`step-${reservationStore.reservationStep}`" v-if="!_.isNull(reservationStore.reservationStep)">

        <div v-show="reservationStore.reservationStep === 'date-time'">
            <div class="container">


                <div class="row">
                    <div class="col-xl-3 col-lg-5 col-md-6">
                        <h2>{{ $t('reservation.select_date') }}</h2>
                        <v-datepicker></v-datepicker>
                    </div>
                    <div class="col-xl-9 col-lg-7 col-md-6">
                        <template v-if="!_.isEmpty(reservationStore.date)">
                            <h2 class="pt-4 pt-md-0" id="timeslots-top">{{ $t('reservation.select_time') }}</h2>

                            <v-timeslots></v-timeslots>

                        </template>
                    </div>
                </div>


            </div>

            <v-actions></v-actions>
        </div>

        <div v-if="reservationStore.reservationStep === 'summary'">
            <div class="container">

                <v-summary></v-summary>

            </div>

            <v-actions></v-actions>
        </div>

        <div v-if="reservationStore.reservationStep === 'authentication'">
            <v-inline-authentication></v-inline-authentication>
        </div>

    </div>
</template>
