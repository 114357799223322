import {defineStore} from 'pinia'

export const useReservationStore = defineStore({
    id: 'reservation',
    state: () => ({
        room: {},
        timeslots: {},
        date: null,
        time: null,
        reservationStep: null,
        discount: null,
        needsSupport: false,
        auth: false,
        csrf: null,
        reservation: null,
        terms_agreed: false
    }),
    getters: {},
    actions: {

        setReservationStep(reservationStep) {
            this.reservationStep = reservationStep
        },

        setRoom(room) {
            this.room = room
        },

        setDate(date) {

            if (_.isNull(date)) {
                this.date = date
                return;
            }

            this.date = new Date(date).toISOString();

            this.loadTimeslots()

        },

        setTime(time) {
            this.time = time
        },

        async loadTimeslots(scrollToTimeslots = true) {
            this.time = null
            this.timeslots = null

            try {
                this.timeslots = await axios.get(route('api.rooms.availability', this.room.id), {params: {date: this.date}})
                    .then((result) => result.data)
            } catch (error) {

            } finally {

                if (scrollToTimeslots) {
                    setTimeout(() => {
                        let element = document.querySelector("#timeslots-top");
                        element.scrollIntoView({
                            behavior: 'smooth'
                        });
                    }, 200)
                }
            }
        },

        setDiscount(discount) {
            this.discount = discount;
        },

        setSupport(event) {
            this.needsSupport = event.target.checked;
        },

        setTerms(event) {
            this.terms_agreed = event.target.checked;
        },

        setCSRF(value) {
            this.csrf = value
        },

        isCurrentReservation(timeslot) {
            if (_.isNull(this.reservation)) {
                return false
            }

            return this.reservation.start_time === timeslot.startTime
        },

        checkAuth() {
            axios.get(route('auth.check')).then((result) => {
                this.csrf = result.data.token
                document.querySelector('meta[name="csrf-token"]').setAttribute('content', result.data.token)
                this.auth = true
            }).catch((error) => {
                this.auth = false
            })
        },

        reset() {
            this.room = {}
            this.timeslots = {}
            this.date = null
            this.time = null
            this.reservationStep = null
            this.discount = null
            this.auth = false
            this.reservation = null
            this.terms_agreed = null
        },

        init(room) {
            this.reset()
            this.room = room
            this.setReservationStep('date-time')
            this.checkAuth()
        },


        initEdit(reservation, room) {
            this.reset()
            this.reservation = reservation
            this.room = room
            this.date = reservation.start_time
            this.setReservationStep('date-time')
            this.loadTimeslots(false)
            this.checkAuth()
        }

    },
})
