window._ = require('lodash');

window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

// Initialize the service worker
if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/serviceworker.js', {
        scope: '.'
    }).then(function (registration) {
    }, function (err) {
    });
}

import {createApp} from 'vue'
import {plugin, defaultConfig} from '@formkit/vue'
import {nl} from '@formkit/i18n'

import {ZiggyVue} from 'ziggy';
import {Ziggy} from './ziggy';

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

import {createI18n, useI18n} from 'vue-i18n'
import lang from './lang/lang.json'

const i18n = createI18n({
    legacy: false,
    locale: 'nl',
    globalInjection: true,
    messages: lang
})

import {createPinia} from 'pinia'

//components
import RegisterForm from './vue/components/forms/RegisterForm'
import LoginForm from './vue/components/forms/LoginForm'
import AccountForm from './vue/components/forms/AccountForm'
import FaqAccordion from './vue/components/FaqAccordion'
import AddToHome from './vue/components/AddToHome'
import ToastComponent from './vue/components/Toast'

//modules
import Rooms from './vue/modules/rooms/Rooms'
import ReservationButton from './vue/modules/reservation/ReservationButton'
import ReservationFlow from './vue/modules/reservation/ReservationFlow'
import EditReservationFlow from './vue/modules/reservation/EditReservationFlow'

//icons
import IconHome from './vue/svg/Home'
import IconCalendar from './vue/svg/Calendar'
import IconProfile from './vue/svg/Profile'
import IconLocation from './vue/svg/Location'
import IconConditioning from './vue/svg/Conditioning'
import IconContact from './vue/svg/Contact'
import IconStrengthConditioning from './vue/svg/StrengthConditioning'
import PrivatefitLogo from './vue/svg/PrivatefitLogo'
import IconInfo from './vue/svg/Info'
import IconSend from './vue/svg/Send'
import IconFaq from './vue/svg/Faq'
import IconPhone from './vue/svg/Phone'
import IconClock from './vue/svg/Clock'
import IconExternal from './vue/svg/External'
import IconWhatsapp from './vue/svg/Whatsapp'
import IconEdit from './vue/svg/Edit'

const app = createApp({
    setup() {
        const t = useI18n()
        return {t}
    }
})
    //i18n
    .use(i18n)

    //formkit
    .use(plugin, defaultConfig({
        locales: {nl},
        locale: 'nl',
    }))

    //ziggy
    .use(ZiggyVue, Ziggy)

    //Pinia
    .use(createPinia())

    //toast
    .use(Toast, {
        closeOnClick: true,
        closeButton: false,
        maxToasts: 1,
    })

    //components
    .component('v-register-form', RegisterForm)
    .component('v-login-form', LoginForm)
    .component('v-account-form', AccountForm)
    .component('v-faq-accordion', FaqAccordion)
    .component('v-add-to-home', AddToHome)
    .component('v-toast', ToastComponent)

    //modules
    .component('v-rooms', Rooms)
    .component('v-reservation-button', ReservationButton)
    .component('v-reservation-flow', ReservationFlow)
    .component('v-edit-reservation-flow', EditReservationFlow)

    //icons
    .component('v-icon-home', IconHome)
    .component('v-icon-calendar', IconCalendar)
    .component('v-icon-profile', IconProfile)
    .component('v-icon-location', IconLocation)
    .component('v-icon-strength-conditioning', IconStrengthConditioning)
    .component('v-icon-contact', IconContact)
    .component('v-icon-conditioning', IconConditioning)
    .component('v-image-placeholder', PrivatefitLogo)
    .component('v-icon-info', IconInfo)
    .component('v-icon-send', IconSend)
    .component('v-icon-faq', IconFaq)
    .component('v-icon-phone', IconPhone)
    .component('v-icon-clock', IconClock)
    .component('v-icon-external', IconExternal)
    .component('v-icon-whatsapp', IconWhatsapp)
    .component('v-icon-edit', IconEdit)

    .mount('#app');
