<script setup>
import {ref} from 'vue'
import {useReservationStore} from "../stores/reservation"
import {useToast} from "vue-toastification";

import {useI18n} from 'vue-i18n'

const i18n = useI18n();

const toast = useToast();
const reservationStore = useReservationStore()

let voucher = ref()

function validateVoucher() {

    axios.get(
        route('vouchers.calculate', {
            'voucher': voucher.value,
            'room': reservationStore.room.id
        })
    ).then((result) => {
        reservationStore.setDiscount(result.data)
        toast.success(i18n.t('reservation.discount.success'));
    }).catch((error) => {
        reservationStore.setDiscount(null);
        toast.warning(i18n.t('reservation.discount.error'));
    })
}

</script>
<template>
    <form @submit.prevent="validateVoucher" class="mt-3 discount-form">
        <input type="text" v-model="voucher">
        <button>{{ $t('reservation.discount.add') }}</button>
    </form>
</template>
