<script setup>
import {ref} from 'vue'
import {default as vIconEye} from '../../svg/Eye';
import {default as vIconEyeOff} from '../../svg/EyeOff';

let inputType = ref('password')
function toggleType() {
    if (inputType.value === 'password') {
        inputType.value = 'text'
        return;
    }
    inputType.value = 'password'
}
</script>

<template>
    <div class="input-password">
        <FormKit id="password" class="" :type="inputType" name="password"
                 :label="$t('user.password')"
                 validation="required"
        />
        <button type="button" @click="toggleType">
            <v-icon-eye v-show="inputType !== 'password'"></v-icon-eye>
            <v-icon-eye-off v-show="inputType === 'password'"></v-icon-eye-off>
        </button>
    </div>
</template>
