<script setup>
import {ref} from 'vue'
import {default as vReservationFlow} from './ReservationFlow'
import {useReservationStore} from "./stores/reservation"

const reservationStore = useReservationStore()

const props = defineProps({
    room: Object
})

function initFlow() {
    reservationStore.init(props.room)
}

</script>
<template>
    <slot :init-flow="initFlow"></slot>
</template>
