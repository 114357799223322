<script setup>
import {ref} from 'vue'
import {useReservationStore} from "../../stores/reservation"
import {DateTime} from 'luxon'
import Popper from "vue3-popper";

const reservationStore = useReservationStore()
let needSupport = ref()
</script>

<template>

    <h2>{{$t('reservation.edit.check_reservation')}}</h2>

    <div class="border reservation-summary">

        <div class="image-shape">
            <img :src="reservationStore.room.image_path" :alt="reservationStore.room.name" v-if="reservationStore.room.image_path">
            <v-image-placeholder v-if="!reservationStore.room.image_path"></v-image-placeholder>
        </div>

        <div class="row">
            <div class="col-12">
                <h3 class="mb-2">{{reservationStore.room.name}}</h3>
                <div class="icon-text mb-2">
                    <v-icon-location></v-icon-location>
                    {{reservationStore.room.location.name}}
                </div>
                <div class="icon-text mb-2">
                    <component :is="`v-icon-${reservationStore.room.type}`"></component>
                    {{reservationStore.room.type_label}}
                </div>
            </div>

            <div class="col-12 mt-2">
                <h3 class="mb-2">{{$t('reservation.edit.new')}}</h3>
                <div class="icon-text mb-2">
                    <v-icon-calendar></v-icon-calendar>
                    {{ DateTime.fromISO(reservationStore.date).toFormat('dd-MM-yyyy') }}
                </div>
                <div class="icon-text mb-2">
                    <v-icon-clock></v-icon-clock>
                    {{ DateTime.fromISO(reservationStore.time.startTime).toFormat('HH:mm') }}
                </div>
            </div>

            <div class="col-12 text-light">

                <hr class="my-2">

                <h3 class="mb-2">{{$t('reservation.edit.old')}}</h3>
                <div class="icon-text mb-2">
                    <v-icon-calendar></v-icon-calendar>
                    {{ DateTime.fromISO(reservationStore.reservation.start_time).toFormat('dd-MM-yyyy') }}
                </div>
                <div class="icon-text mb-2">
                    <v-icon-clock></v-icon-clock>
                    {{ DateTime.fromISO(reservationStore.reservation.start_time).toFormat('HH:mm') }}
                </div>
            </div>

        </div>
    </div>

</template>
