let items = [
    {
        "title": "Studio's",
        "items": [
            {
                'title': 'Hoe krijg ik toegang tot de gereserveerde studio?',
                'description': '<ol>' +
                    '<li>Bij je reservering ontvang je een 6 cijferige reserveringscode</li>' +
                    '<li>Bij zowel de voordeur, als de deur van de studio, hangt een kastje waar je deze kan intoetsen</li>' +
                    '<li>Toets de code in, en sluit af met een #</li>' +
                    '<li>Wacht tot het lampje groen wordt, je kan nu de deur openen</li>' +
                    '<li>Kom je er niet uit? Neem <a href="/contact">contact</a> op!</li>' +
                    '</ol>',
            },
            {
                'title': 'Wat als er iets gebeurt tijdens mijn workout?',
                'description': 'In elke studio hangt een rode noodknop. Let op: bij onrechtmatig gebruik van de noodknop zullen er kosten in rekening gebracht worden..',
            },
            {
                'title': 'Kan ik iemand meenemen?',
                'description': 'Jazeker, de ruimtes zijn geschikt tot 3 personen.',
            },
            {
                'title': 'Hoelang kan ik een studio reserveren?',
                'description': 'Een standaard reservering is een uur. Je kan natuurlijk wel meerdere reserveringen achter elkaar plannen.',
            },
            {
                'title': 'Hebben alle studio\'s dezelfde apparatuur?',
                'description': 'Nee, de apparaten kunnen per studio verschillen. Kijk daarom van tevoren goed of de studio die je wil reserveren, alles heeft voor jouw workout.',
            },
        ]
    },
    {
        "title": "Algemeen",
        "items": [
            {
                'title': 'Welke faciliteiten zijn er aanwezig?',
                'description': 'Er zijn toiletten en douches aanwezig, deze vind je in de hal.',
            },
            {
                'title': 'Moet ik een abonnement afsluiten?',
                'description': 'Nee, wij hanteren geen abonnementskosten. Je betaalt voor je reservering.',
            },
            {
                'title': 'Wat zijn de tarieven?',
                'description': 'Het tarief voor het reserveren van een studio is € 15,- per studio, per uur',
            },
            {
                'title': 'Kan ik mijn reservering annuleren?',
                'description': 'Op dit moment is het niet mogelijk om je reservering te annuleren. Wel kan je, tot 24 uur van tevoren, de dag en tijd wijzigen.'
            }
        ]
    },
    {
        "title": "Huisregels",
        "items": [
            {
                'title': 'Hoe wordt de hygiëne van de studio\'s gewaarborgd?',
                'description': 'Na elk bezoek wordt de studio schoongemaakt.',
            },
            {
                'title': 'Hoe veilig zijn de studio\'s?',
                'description': 'Overal is camera toezicht, en we hebben een noodknop.',
            }
        ]
    }
];

module.exports = items

