<script setup>
import {onMounted, ref} from 'vue'

const cookieName = 'addToHome'
let showButton = ref(false)
let showNotification = ref(!getCookie(cookieName))
let deferredPrompt = ref(null)

onMounted(() => {
    window.addEventListener('beforeinstallprompt', (e) => {
        e.preventDefault()
        deferredPrompt.value = e
        showButton.value = true
    });
})

function promptUser() {
    deferredPrompt.value.prompt()
    deferredPrompt.value.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
            hideNotification()
        }
        deferredPrompt.value = null
    });
}

function hideNotification() {
    setCookie(cookieName, true, 30)
    showNotification.value = false
}

function setCookie(cName, cValue, expDays) {
    let date = new Date()
    date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000))
    const expires = "expires=" + date.toUTCString()
    document.cookie = cName + "=" + cValue + "; " + expires + "; path=/"
}

function getCookie(cName) {
    const name = cName + "="
    const cDecoded = decodeURIComponent(document.cookie)
    const cArr = cDecoded.split('; ')
    let res
    cArr.forEach(val => {
        if (val.indexOf(name) === 0) res = val.substring(name.length)
    })
    return res;
}

</script>
<template>
    <Transition appear>
        <div class="card bordered add-to-home mb-3" v-if="showNotification">
            <strong>{{ $t('addtohome.tip') }}</strong>
            <button class="close" @click="hideNotification()"></button>
            <p class="my-2">{{ $t('addtohome.add_to_use') }}</p>
            <button class="add-button" @click="promptUser" v-if="showButton">{{ $t('addtohome.add') }}</button>
        </div>
    </Transition>
</template>
