<script setup>
import {DateTime} from 'luxon'

import {useReservationStore} from "../stores/reservation"
const reservationStore = useReservationStore()

const props = defineProps({
    timeslot: Object
})

</script>
<template>
    <button
        class="d-flex align-items-center justify-content-between btn w-100 mt-2 btn-secondary"
        :disabled="!timeslot.available"
        @click="reservationStore.setTime(timeslot)"
        :class="{
                'btn-outline' : (reservationStore.time === timeslot || !timeslot.available),
            }"
        >
        <span>
            {{ DateTime.fromISO(timeslot.startTime).toFormat('HH:mm') }}
            -
            {{ DateTime.fromISO(timeslot.endTime).toFormat('HH:mm') }}
        </span>
        <span class="font-weight-medium" v-if="reservationStore.reservation === null">
                {{ timeslot.price.formatted }}
        </span>
        <small class="font-weight-medium" v-if="reservationStore.isCurrentReservation(timeslot)">
                {{$t('reservation.edit.current')}}
        </small>
    </button>
</template>
