<script setup>
import {useGtm} from "../../analytics/stores/analytics";

const props = defineProps({
    room: Object
})

const pushProductClickDatalayer = (locationUrl) => {
    let dlObject = {
        'event': 'productClick',
        'ecommerce': {
            'click': {
                'products': [{
                    'name': props.room.title,
                }]
            }
        }
    };

    useGtm().dataLayerPush(dlObject)
}

</script>

<template>
    <Transition appear>
        <div class="card mb-3">
            <div class="row mb-3">
                <div class="col-4 pe-0">
                    <img :src="room.image_path" :alt="room.name" v-if="room.image_path">
                    <v-image-placeholder v-if="!room.image_path"></v-image-placeholder>
                </div>
                <div class="col">
                    <strong>{{ room.name }}</strong>
                    <div class="icon-text mb-2 text-light">
                        <v-icon-location></v-icon-location>
                        <div>
                            {{ room.location.name }}
                        </div>
                    </div>

                    <div class="icon-text text-light">
                        <component :is="`v-icon-${room.type}`"></component>
                        <div>{{ room.type_label }}</div>
                    </div>
                </div>
            </div>

            <div class="d-flex align-items-center justify-content-between">
                <a :href="route('rooms.show', room.id)" @click="pushProductClickDatalayer" class="read-more">
                    {{ $t('rooms.more_info') }}
                </a>

                <v-reservation-button v-slot="scope" :room="room">
                    <button class="shape-button" @click="pushProductClickDatalayer(undefined); scope.initFlow()">
                        {{ $t('rooms.reserve') }}
                    </button>
                </v-reservation-button>
            </div>
        </div>
    </Transition>
</template>
