<script setup>
import {ref} from 'vue'
import _ from 'lodash'
import {useReservationStore} from "../stores/reservation"

const reservationStore = useReservationStore()
reservationStore.setCSRF(document.querySelector('meta[name="csrf-token"]').getAttribute('content'))

let submitDisabled = ref(false);

function disableSubmit() {
    submitDisabled.value = true;
}

</script>
<template>
    <div class="action-bar">
        <div class="container py-2">

            <template v-if="reservationStore.reservationStep === 'date-time'">
                <small><strong>{{ reservationStore.room.name }}</strong></small><br>
                <small class="icon-text small">

                    <v-icon-location></v-icon-location> &nbsp;
                    {{ reservationStore.room.location.name }}
                    &nbsp; &nbsp; &nbsp;
                    <component :is="`v-icon-${reservationStore.room.type}`"></component> &nbsp;
                    {{ reservationStore.room.type_label }}
                </small>

                <div class="d-flex justify-content-between mt-2">
                    <button @click="reservationStore.reset" class="btn-outline-inactive">{{ $t('reservation.back') }}
                    </button>
                    <button @click="reservationStore.setReservationStep('summary')" :disabled="_.isNull(reservationStore.time)" class="btn-primary ms-3 w-100">
                        {{ $t('reservation.check_pay') }}
                    </button>
                </div>

            </template>

            <template v-if="reservationStore.reservationStep === 'summary'">
                <div class="d-flex justify-content-between mt-2">
                    <button @click="reservationStore.setReservationStep('date-time')" class="btn-outline-inactive">
                        {{ $t('reservation.back') }}
                    </button>

                    <form :action="route('reservations.store')" method="post" class="w-100 ms-3" @submit="disableSubmit" v-if="reservationStore.auth">
                        <input type="hidden" name="_token" :value="reservationStore.csrf">
                        <input type="hidden" name="room_id" :value="reservationStore.room.id">
                        <input type="hidden" name="start_time" :value="reservationStore.time.startTime">
                        <input type="hidden" name="voucher" :value="!_.isNull(reservationStore.discount) ? reservationStore.discount.code : ''">
                        <input type="hidden" name="needs_support" :value="reservationStore.needsSupport ? 1 : 0">

                        <template v-if="!_.isNull(reservationStore.discount) && reservationStore.discount.discount_price.amount === '0'">

                            <button type="submit" class="btn-primary w-100"
                                :class="{'btn-loader':reservationStore.terms_agreed}"
                                :disabled="submitDisabled || !reservationStore.terms_agreed"
                            >
                                {{ $t('reservation.finish') }}
                            </button>
                        </template>
                        <template v-else>
                            <button type="submit" class="btn-primary w-100 ideal"
                                :class="{'btn-loader':reservationStore.terms_agreed}"
                                :disabled="submitDisabled || !reservationStore.terms_agreed"
                            >
                                {{ $t('reservation.checkout') }}
                            </button>
                        </template>
                    </form>

                    <button type="submit" class="btn-primary w-100 ms-3" v-if="!reservationStore.auth" @click="reservationStore.setReservationStep('authentication')">
                        {{ $t('reservation.register_pay') }}
                    </button>

                </div>
            </template>

        </div>
    </div>
</template>
