<template>
    <svg width="1.125em" height="1.125em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_962_8733)">
            <path d="M23.4804 8.54837L15.5696 5.32502H9.55975L2.42823 2.10167C2.01105 1.90882 1.51908 2.0938 1.32623 2.50705C1.13338 2.92423 1.31836 3.4162 1.73161 3.60905L9.2567 7.08822V13.2201L0.401338 21.304C-0.102433 21.7606 -0.137855 22.5398 0.318688 23.0436C0.775232 23.5474 1.5545 23.5828 2.05827 23.1263L10.4846 15.4359H14.9359L18.2616 17.1322C18.4269 17.2227 18.486 17.4352 18.3915 17.6005L16.0497 21.5441C15.7073 22.1227 15.8923 22.8665 16.463 23.2168C17.0494 23.5749 17.8169 23.3821 18.1632 22.7878L21.359 17.3053C21.6857 16.7347 21.4732 16.0066 20.8946 15.7035L15.5696 12.8344V7.14726L22.8585 10.0872C23.2836 10.2604 23.7677 10.0558 23.9409 9.63069C24.114 9.20564 23.9094 8.72154 23.4843 8.54837H23.4804Z" fill="currentColor"/>
            <path d="M12.4133 4.49852C13.657 4.49852 14.6646 3.49098 14.6646 2.24729C14.6646 1.00361 13.657 0 12.4133 0C11.1697 0 10.1621 1.00754 10.1621 2.25123C10.1621 3.49492 11.1697 4.50246 12.4133 4.50246V4.49852Z" fill="currentColor"/>
        </g>
        <defs>
            <clipPath id="clip0_962_8733">
                <rect width="1.125em" height="1.125em" fill="currentColor"/>
            </clipPath>
        </defs>
    </svg>
</template>
