<script setup>
import {useRoomStore} from "../stores/rooms"
import {default as vRoom} from "./Room"
import {default as vLoader} from '../../../components/Loader'

import _ from 'lodash'
import {useGtm} from "../../analytics/stores/analytics";

const roomStore = useRoomStore()

roomStore.$subscribe((mutation, state) => {
    if (_.isEmpty(state.rooms)) {
        return
    }

    useGtm().dataLayerPush({
        'ecommerce': {
            'impressions':
                roomStore.rooms.map((room, index) => {
                    return {
                        'name': room.title,
                        'list': 'Overview filter',
                        'position': index + 1
                    }
                })
        }
    })

})
</script>
<template>
    <v-loader v-if="_.isEmpty(roomStore.rooms)"></v-loader>
    <v-room v-for="room in roomStore.rooms" :room="room"></v-room>
</template>
