import {defineStore} from 'pinia'

export const useRoomStore = defineStore({
    id: 'rooms',
    state: () => ({
        roomTypes: {},
        activeRoomType: null,
        rooms: {},
    }),
    getters: {},
    actions: {

        setRoomTypes(types) {
            this.roomTypes = types
        },

        setActiveRoomType(type) {

            if (this.activeRoomType === type) {
                this.activeRoomType = null
            } else {
                this.activeRoomType = type
            }

            this.loadRooms()
        },

        async loadRooms() {
            this.rooms = {}

            try {
                let params = {}
                if (!_.isNull(this.activeRoomType)) {
                    params.type = this.activeRoomType
                }

                await axios.get(route('api.rooms.index'), {params})
                    .then((result) => {
                        this.rooms = result.data
                    })
            } catch (error) {

            } finally {

            }
        }

    },
})
