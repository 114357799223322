<script setup>
import {ref, defineEmits} from 'vue'
import {setErrors} from '@formkit/vue'
import {default as vPasswordField} from './PasswordField'
import Popper from "vue3-popper";

let isPersonalTrainer = ref(false);
let formLoading = ref(false);

const emit = defineEmits(['switchForm','authSuccess'])

const props = defineProps({
    flow: {type: String, required: false, default: 'page'}
})

async function handleSubmit(credentials) {

    formLoading.value = true;

    return axios.post(route('register'), credentials)
        .then( () => {

            if (props.flow === 'page') {
                window.location.href = route('home');
            } else {
                emit('authSuccess')
            }

        })
        .catch((error) => {
            formLoading.value = false;

            setErrors(
                'register-form',
                [error.response.data.message],
                error.response.data.errors
            )

        });
}

function switchForm() {
    emit('switchForm', 'login')
}
</script>

<template>
    <FormKit type="form" @submit="handleSubmit"
             id="register-form"
             class=""
             :actions="false"
             :classes="{
                    form: {
                      'd-flex flex-column': true
                    }
                  }"
    >
        <div class="form-item">
            <FormKit id="first_name" class="" type="text" name="first_name"
                     :label="$t('user.first_name')"
                     validation="required"
            />
        </div>

        <div class="form-item">
            <FormKit id="last_name" class="" type="text" name="last_name"
                     :label="$t('user.last_name')"
                     validation="required"
            />
        </div>

        <div class="form-item">
            <FormKit id="email" type="email" name="email"
                     :label="$t('user.email')"
                     validation="required|email"
            />
        </div>

        <div class="form-item">
            <FormKit id="phone_number" class="" type="text" name="phone_number"
                     :label="$t('user.phone_number')"
                     validation="required"
            />
        </div>

        <div class="d-flex justify-content-between align-items-start">

            <div class="form-item form-type-checkbox me-3">
                <FormKit id="is_personal_trainer" type="checkbox" name="is_personal_trainer"
                         :label="$t('user.is_personal_trainer')"
                         validation="required"
                         v-model="isPersonalTrainer"
                />
            </div>
        </div>


        <div v-show="isPersonalTrainer" class="mt-5">
            <div class="form-item">
                <FormKit id="company_name" class="" type="text" name="company_name"
                         :label="$t('user.company_name')"
                />
            </div>

            <div class="form-item">
                <FormKit id="address" class="" type="text" name="address"
                         :label="$t('user.address')"
                />
            </div>

            <div class="form-item">
                <FormKit id="postal_code" class="" type="text" name="postal_code"
                         :label="$t('user.postal_code')"
                />
            </div>

            <div class="form-item">
                <FormKit id="city" class="" type="text" name="city"
                         :label="$t('user.city')"
                />
            </div>


        </div>


        <div class="form-item mt-5">
            <v-password-field></v-password-field>
        </div>

        <div class="form-item form-type-checkbox">
            <FormKit id="terms_of_service" type="checkbox" name="terms_of_service" label=""
                     validation="required"
            />
            <label for="terms_of_service">{{ $t('user.agree') }} <a :href="route('terms')" target="_blank" >{{ $t('user.terms') }}</a> </label>
        </div>

        <div class="d-flex align-items-center justify-content-between form-buttons">

            <a :href="route('login')" v-if="props.flow === 'page'">{{ $t('user.to_login') }}</a>
            <button type="button" @click="switchForm()" v-if="flow === 'inline'">{{ $t('user.to_login') }}</button>

            <FormKit
                type="submit"
                class="btn btn-primary"
                :label="$t('user.register')"
                :classes="{
                    input: {
                      'btn btn-primary btn-loader':true
                    }
                  }"
                :disabled="formLoading"
            />
        </div>
    </FormKit>
</template>
