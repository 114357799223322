import {defineStore} from 'pinia'

export const useGtm = defineStore('gtm', {
    state: () => ({}),

    actions: {
        dataLayerPush(data) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push(data);
        },

    },
})
